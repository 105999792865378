html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: overlay;
  /* background: linear-gradient(#141e30, #243b55); */
}
/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(90, 90, 90);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
} */
body::-webkit-scrollbar {
  display: none;
}

.back-button {
  margin-left: 30px !important;
  color: #F9CA26 !important;
  background: #1A1B28 !important;
  box-shadow: 0 10px 15px rgba(0, 0, 0, .6);
}

.back-button:hover {
  background: #F9CA26 !important;
  color: #1A1B28 !important;
}

.outer {
  width: 100%;
  min-height: 80vh;
  padding: 60px 0;
  display: grid;
  place-items: center;
}

.login-box {
  width: 400px;
  padding: 40px;
  background: #1A1B28;
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
  border-radius: 10px;
}

@media only screen and (max-width: 500px) {
  .login-box {
    width: fit-content;
  }
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1A1B28 inset !important;
  -webkit-text-fill-color: white !important;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus~label,
.login-box .user-box input:valid~label {
  top: -20px;
  left: 0;
  color: #F9CA26;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #F9CA26;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px
}

.login-box a:hover {
  background: #F9CA26;
  color: #1A1B28;
  border-radius: 5px;
}

.login-box a span {
  position: absolute;
  display: block;
}

/* .login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #F9CA26);
    animation: btn-anim1 1s linear infinite;
  } */

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

